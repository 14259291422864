<script setup lang="ts">
import { computed, ref } from 'vue'

import MessageOutput from './Output.vue'

import Loader from '../Loader.vue'
import RateOutput, { RateOutputProps } from '../RateOutput.vue'

import { ChatBubbleStyle, LoaderStyle, TextMode } from '../../Widget.types'

const props = defineProps<{
  index: number
  message: any
  rateMessage: string
  isCircular: boolean
  isLoading: boolean
  isStreaming: boolean
  textMode: TextMode
  ratingIndex: any[]
  totalMessages: number
  chatBubbleStyle: ChatBubbleStyle
  agentResponseUrl: string
  loaderStyle: LoaderStyle
}>()

const showLoader = computed(() => {
  return props.isLoading && !props.message.content
})

const rateOutputProps = computed(() => {
  const { index, message, isStreaming, ratingIndex, totalMessages } = props
  return { index, message, isStreaming, ratingIndex, totalMessages } as RateOutputProps
})

const showRatingOption = ref(false)

const messageTypes = ['assistant', 'assistant-rating']

const isAssistantRatingReply = computed(() => {
  return props.message.role === 'assistant-rating'
})

const showMessage = computed(() => {
  return messageTypes.includes(props.message.role)
})

const emit = defineEmits(['updateRating'])
</script>

<template>
  <div
    v-if="showMessage"
    class="rw-group rw-mt-4 rw-flex rw-w-full rw-space-x-3"
    @click="showRatingOption = true"
    @mouseleave="showRatingOption = false"
  >
    <Loader v-if="showLoader" :loader-style="loaderStyle" :chat-bubble-style="chatBubbleStyle" />
    <template v-else>
      <!-- Intentional spacer if no image set -->
      <div class="rw-min-w-[44px]">
        <img v-if="agentResponseUrl" class="rw-max-h-[44px]" :src="agentResponseUrl" />
      </div>

      <!-- Message -->
      <div class="rw-ms-auto">
        <div
          class="rw-relative rw-break-words rw-bg-primaryColor rw-px-4 rw-py-3 rw-leading-5 rw-shadow-lg"
          :class="[
            {
              'bubble-gradient': isCircular,
            },
            chatBubbleStyle === ChatBubbleStyle.ROUNDED
              ? 'rw-rounded-r-3xl rw-rounded-bl-3xl'
              : 'rw-rounded-r-lg rw-rounded-bl-lg',
          ]"
        >
          <MessageOutput
            :content="message.content"
            :light-mode="textMode === TextMode.LIGHT"
            :dark-mode="textMode === TextMode.DARK"
          />

          <RateOutput
            v-if="!isAssistantRatingReply && !showLoader && message.content"
            v-bind="rateOutputProps"
            :rate-message="rateMessage"
            :show-rating-option="showRatingOption"
            @update-rating="emit('updateRating', $event)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.bubble-gradient {
  background: linear-gradient(to bottom right, var(--headerGradientHighlight), var(--primaryColor));
}
</style>
