import { ref } from 'vue'

import { useIsChatOpen } from './useIsChatOpen.js'

const isReady = ref(false)
const isLoading = ref(false)

const DEVELOPER_MODE = false

export const useSendMessage = (endpoint, apikey, messages, { healthPath, healthOpenAiPath }) => {
  const { isChatOpen } = useIsChatOpen()
  const response = ref()

  const checkHealth = () => {
    if (DEVELOPER_MODE) {
      isReady.value = true
      return
    }

    fetch(`${endpoint}${healthPath}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(({ status }) => {
        if (status === 'ok') {
          isReady.value = true
        } else {
          isReady.value = false
          isChatOpen.value = false
        }
      })
      .catch(() => {
        isReady.value = false
        isChatOpen.value = false
      })
    fetch(`${endpoint}${healthOpenAiPath}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(({ status }) => {
        if (status !== 'ok') {
          if (messages.value.length > 1) {
            messages.value = messages.value.slice(0, -1)
            messages.value = [
              ...messages.value,
              { content: 'OpenAi is reporting server issues and responses may be slow.', role: 'assistant' },
            ]
          }
        }
      })
      .catch(() => {})
  }

  const request = (path, payload) => {
    isLoading.value = true

    return fetch(`${endpoint}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(apikey && { Authorization: `Bearer ${apikey}` }),
      },
      body: JSON.stringify(payload),
    })
      .then(async response => {
        isLoading.value = false

        if (!response.ok) {
          checkHealth()
          messages.value = messages.value.slice(0, -1)
          messages.value = [
            ...messages.value,
            { content: 'Sorry, there was a server error, try sending your message again', role: 'system' },
          ]
          return null
        }

        response.value = response
        return response
      })
      .catch(error => {
        isLoading.value = false
        console.error(error)

        messages.value = messages.value.slice(0, -1)
        messages.value = [
          ...messages.value,
          { content: 'Sorry, there was a server error, try sending your message again', role: 'system' },
        ]

        return null
      })
  }

  return {
    response,
    request,
    isReady,
    isLoading,
    checkHealth,
  }
}
