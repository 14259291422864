import { ChatBubbleStyle, HeaderStyle, LoaderStyle, RatingStyle } from './Widget.types.ts'

export const defaultApiPaths = {
  sendMessage: '/api/public-chat',
  memory: '/api/memory/chat-history',
  health: '/api/health',
  healthOpenAi: '/api/health/openai',
}

export const defaultContent = {
  aiName: '',
  modalTitle: "Hey there, I'm {{aiName}}!",
  chattingWith: 'Chatting with {{aiName}}',
  introduction:
    "<p>I'm an AI powered chatbot built by the team at Revium and trained on data you can find on this site.</p><p>How can I help you?</p>",
  inputPlaceholder: 'Ask me a question!',
  rateMessage: 'Rate this response',
  footnote:
    "Built by <a href='https://revium.com.au/' target='_blank'>Revium</a> and Powered by <a href='https://openai.com/' target='_blank'>OpenAI</a>",
}

export const defaultAssetUrls = {
  chatToggle: '',
  chatHeader: '',
  agentResponse: '',
}

export const defaultLayout = {
  borderRadius: 12,
  submitBorderWidth: 1,
  headerTitleIndent: true,
  headerAssetWidth: 0,
  headerAssetTopOffset: 0,
  headerAssetLeftOffset: 0,
  chatBubbleStyle: ChatBubbleStyle.BLOCK,
  headerStyle: HeaderStyle.FLAT,
  loaderStyle: LoaderStyle.LEFT_GRAY,
  ratingStyle: RatingStyle.OUTLINE_GRAY,
}
