<script setup lang="ts">
interface RateOutputProps {
  index: number
  message: any
  rateMessage: string
  isStreaming: boolean
  ratingIndex: any[]
  totalMessages: number
  ratingIsPositive: boolean
  showRatingOption: boolean
}

defineProps<RateOutputProps>()

const emit = defineEmits(['updateRating'])
</script>

<template>
  <Transition>
    <div
      v-if="showRatingOption"
      class="rw-align-items-start z-10 rw-absolute rw-bottom-[-5px] rw-right-[-5px] rw-ms-auto rw-flex rw-min-h-fit rw-justify-center rw-rounded-[5px] rw-bg-white rw-shadow-xl"
    >
      <div class="rw-flex rw-w-full rw-justify-end rw-ps-2">
        <div class="rw-flex rw-items-center rw-ps-1 rw-text-xs">{{ rateMessage }}</div>

        <div
          class="rw-flex rw-h-[30px] rw-w-[75px] rw-items-center rw-justify-center rw-rounded-bl-lg rw-rounded-br-lg rw-from-30%"
        >
          <div
            class="rw-cursor-pointer rw-px-1.5"
            :class="`${ratingIsPositive ? 'rw-opacity-100' : 'rw-opacity-50'} hover:rw-opacity-100`"
            @click="
              emit('updateRating', {
                rating: 1,
                content: message.content,
                index,
              })
            "
          >
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 2.5625L8.875 3.09375C8.53125 4.34375 7.71875 5.4375 6.625 6.125L6.53125 6.1875C6.28125 6.34375 6.0625 6.5625 5.875 6.8125C5.71875 7.03125 5.40625 7.09375 5.1875 6.9375C4.96875 6.75 4.90625 6.4375 5.0625 6.21875C5.3125 5.875 5.625 5.59375 6 5.34375L6.09375 5.28125C6.96875 4.71875 7.625 3.84375 7.90625 2.8125L8.03125 2.3125C8.3125 1.375 9.25 0.8125 10.1875 1.0625C11.125 1.34375 11.6875 2.28125 11.4375 3.21875L11.2812 3.75C11.1562 4.1875 11 4.59375 10.8125 5H14C15.0938 5 16 5.90625 16 7C16 7.5625 15.75 8.0625 15.4062 8.4375C15.4688 8.625 15.5 8.8125 15.5 9C15.5 9.59375 15.2188 10.125 14.8438 10.5C14.9375 10.7188 15 11 15 11.25C15 12 14.5938 12.6562 14 13C14 14.125 13.0938 15 12 15H9.1875C8.5 15 7.8125 14.8125 7.25 14.4375L6.03125 13.625C5.65625 13.3438 5.3125 13.0312 5.0625 12.625C4.90625 12.4062 4.96875 12.0938 5.21875 11.9375C5.4375 11.7812 5.75 11.8438 5.90625 12.0938C6.09375 12.375 6.3125 12.5938 6.59375 12.7812L7.8125 13.5938C8.21875 13.875 8.6875 14 9.1875 14H12C12.5312 14 13 13.5625 13 13C13 12.9375 12.9688 12.875 12.9688 12.8125C12.9062 12.5625 13.0625 12.2812 13.3125 12.2188C13.6875 12.0938 14 11.7188 14 11.2812C14 11.0312 13.9062 10.8125 13.7812 10.6562C13.6875 10.5312 13.6562 10.4062 13.6875 10.25C13.7188 10.0938 13.8125 9.96875 13.9375 9.90625C14.2812 9.75 14.5 9.40625 14.5 9.03125C14.5 8.84375 14.4375 8.65625 14.3438 8.53125C14.2188 8.28125 14.3125 8 14.5312 7.875C14.8125 7.6875 15 7.375 15 7.03125C15 6.46875 14.5312 6.03125 14 6.03125H9.96875C9.78125 6.03125 9.625 5.90625 9.53125 5.75C9.4375 5.59375 9.4375 5.40625 9.53125 5.25C9.875 4.71875 10.1562 4.09375 10.3125 3.46875L10.4688 2.96875C10.5625 2.5625 10.3438 2.15625 9.9375 2.03125C9.53125 1.9375 9.125 2.15625 9 2.5625ZM1 7V14H3V7H1ZM0 7C0 6.46875 0.4375 6 1 6H3C3.53125 6 4 6.46875 4 7V14C4 14.5625 3.53125 15 3 15H1C0.4375 15 0 14.5625 0 14V7Z"
                fill="#31C037"
              />
            </svg>
          </div>
          <div
            class="rw-cursor-pointer rw-px-1.5 rw-pt-1"
            :class="`${
              ratingIndex.find(rIndex => rIndex.index === index && rIndex.rating === 0)
                ? 'rw-opacity-100'
                : 'rw-opacity-50'
            } hover:rw-opacity-100`"
            @click="
              emit('updateRating', {
                rating: 0,
                content: message.content,
                index,
              })
            "
          >
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 12.4688C9.125 12.875 9.53125 13.0938 9.9375 13C10.3438 12.875 10.5625 12.4688 10.4688 12.0625L10.3125 11.5625C10.1562 10.9062 9.875 10.3125 9.53125 9.78125C9.4375 9.625 9.4375 9.4375 9.53125 9.28125C9.625 9.09375 9.78125 9 9.96875 9H14C14.5312 9 15 8.5625 15 8C15 7.65625 14.8125 7.34375 14.5312 7.15625C14.3125 7.03125 14.2188 6.71875 14.3438 6.5C14.4375 6.34375 14.5 6.1875 14.5 6C14.5 5.625 14.2812 5.28125 13.9375 5.125C13.8125 5.0625 13.7188 4.9375 13.6875 4.78125C13.6562 4.625 13.6875 4.5 13.7812 4.375C13.9062 4.1875 14 4 14 3.75C14 3.3125 13.6875 2.9375 13.3125 2.8125C13.0625 2.71875 12.9062 2.46875 12.9688 2.21875C12.9688 2.15625 13 2.09375 13 2C13 1.46875 12.5312 1 12 1H9.1875C8.6875 1 8.21875 1.15625 7.8125 1.4375L6.59375 2.25C6.3125 2.4375 6.09375 2.65625 5.90625 2.9375C5.75 3.1875 5.4375 3.25 5.21875 3.09375C4.96875 2.9375 4.90625 2.625 5.0625 2.40625C5.3125 2 5.65625 1.65625 6.03125 1.40625L7.25 0.59375C7.8125 0.21875 8.5 0 9.1875 0H12C13.0938 0 14 0.90625 14 2V2.03125C14.5938 2.375 15 3.03125 15 3.75C15 4.03125 14.9375 4.3125 14.8438 4.53125C15.2188 4.90625 15.5 5.4375 15.5 6C15.5 6.21875 15.4688 6.40625 15.4062 6.59375C15.75 6.96875 16 7.46875 16 8C16 9.125 15.0938 10 14 10H10.8125C11 10.4375 11.1562 10.8438 11.2812 11.2812L11.4375 11.8125C11.6875 12.75 11.125 13.6875 10.1875 13.9688C9.25 14.2188 8.3125 13.6562 8.03125 12.7188L7.90625 12.2188C7.625 11.1875 6.96875 10.3125 6.09375 9.75L6 9.6875L6.25 9.28125L6 9.6875C5.625 9.4375 5.3125 9.15625 5.0625 8.8125C4.90625 8.5625 4.96875 8.25 5.1875 8.09375C5.40625 7.9375 5.71875 8 5.875 8.21875C6.0625 8.46875 6.28125 8.6875 6.53125 8.84375L6.625 8.90625C7.71875 9.59375 8.53125 10.6875 8.875 11.9375L9 12.4688ZM1 10H3V3H1V10ZM0 10V3C0 2.46875 0.4375 2 1 2H3C3.53125 2 4 2.46875 4 3V10C4 10.5625 3.53125 11 3 11H1C0.4375 11 0 10.5625 0 10Z"
                fill="#CF9708"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped></style>
