<script setup lang="ts">
import { ref, watch } from 'vue'

const props = defineProps<{
  /** Once chat begins, show short header variation */
  shrinkHeader: boolean
  /** Avoids content overflowing in a very short window */
  visible: boolean
}>()

const localVisible = ref(false)

/** Avoids immediately moving chat upwards during closure */
watch(
  () => props.visible,
  bool => {
    if (bool) {
      localVisible.value = true
    } else {
      setTimeout(() => {
        localVisible.value = false
      }, 500)
    }
  }
)

const chatContainer = ref(null)
defineExpose({ chatContainer })
</script>

<template>
  <div
    ref="chatContainer"
    class="rw-relative rw-w-full rw-grow rw-overflow-auto rw-rounded-lg rw-bg-white rw-px-4 rw-transition-[padding]"
    :class="[shrinkHeader && '!rw-pt-[5px]', localVisible && 'rw-mt-[80px] rw-pt-[60px]']"
  >
    <slot />
  </div>
</template>
