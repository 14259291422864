<script setup lang="ts">
import { computed } from 'vue'

import { TextMode } from '../Widget.types'

const props = defineProps<{
  placeholder: string
  submitBorderWidth: number
  isChatOpen: boolean
  textMode: string
  useSecondaryColor: boolean
}>()

const svgStrokeWidth = computed(() => {
  return props.submitBorderWidth >= 2 ? 1 : 0
})

const emit = defineEmits(['submit'])
const message = defineModel()
</script>

<template>
  <div v-if="isChatOpen" class="rw-w-full rw-bg-white rw-p-4">
    <div class="rw-relative rw-flex rw-w-full">
      <form class="rw-relative rw-flex rw-w-full" @submit="emit('submit', $event)">
        <input
          id="chat-widget-input"
          v-model="message"
          type="text"
          autocomplete="off"
          :placeholder="placeholder"
          class="rw-placeholder-text-gray-600 rw-w-[calc(100%-80px)] rw-rounded-full rw-border rw-bg-white rw-py-3 rw-pl-4 rw-pr-[20px] rw-text-base rw-leading-5 rw-text-gray-600 rw-shadow-lg rw-shadow-gray-100 placeholder:rw-text-gray-500 focus:rw-outline-primaryColor"
        />
        <div
          class="rw-absolute rw-inset-y-0 rw-right-0 rw-h-full rw-w-[70px] rw-items-center sm:rw-flex"
          :class="`${textMode === TextMode.LIGHT ? 'rw-text-white' : 'rw-text-black'}`"
        >
          <button
            id="revium-chatbot-submit"
            type="submit"
            class="!rw-m-0 rw-flex rw-h-full rw-w-full rw-items-center rw-justify-center rw-rounded-full rw-border-solid rw-border-primaryColor rw-bg-white rw-px-4 rw-py-3 rw-leading-5 rw-text-primaryColor rw-transition rw-duration-500 rw-ease-in-out disabled:rw-opacity-40"
            :class="[
              useSecondaryColor && message
                ? 'hover:rw-border-secondaryColor hover:rw-text-secondaryColor focus:rw-outline-secondaryColor'
                : 'focus:rw-outline-none',
            ]"
            :style="{
              borderWidth: `${submitBorderWidth}px`,
            }"
            :disabled="!message"
          >
            <svg
              class="rw-mr-1"
              width="26"
              height="25"
              viewBox="0 0 26 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.9531 2.73438L21.9531 22.2344C21.9062 22.7031 21.625 23.125 21.2031 23.3125C20.8281 23.5469 20.3125 23.5938 19.8906 23.4062L14.5469 21.1562L12.0625 24.25C11.6875 24.7656 11.125 25 10.5156 25C9.39062 25 8.5 24.1094 8.5 22.9844V18.625L1.89062 15.9062C1.375 15.6719 1 15.2031 1 14.5938C0.953125 14.0312 1.23438 13.5156 1.75 13.2344L22.75 1.23438C23.2188 0.953125 23.875 0.953125 24.3438 1.28125C24.8125 1.60938 25.0469 2.17188 24.9531 2.73438ZM13.0938 20.5938L10 19.2812V22.9844C10 23.3125 10.1875 23.5 10.5156 23.5C10.6562 23.5 10.7969 23.4531 10.8906 23.3125L13.0938 20.5938ZM15.1094 19.7969L20.5 22L23.2188 4.32812L10.6094 17.9219L15.1094 19.7969ZM22.0938 3.29688L2.5 14.5L9.10938 17.2656L22.0938 3.29688Z"
                stroke="currentColor"
                stroke-linecap="round"
                :stroke-width="svgStrokeWidth"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
