import { Ref, ref } from 'vue'

export const useScrollToLast = (modalRef: Ref) => {
  const scrollDebounce = ref()

  const scrollToLast = () => {
    clearTimeout(scrollDebounce.value)
    scrollDebounce.value = setTimeout(() => {
      const chatContainer = modalRef.value?.chatContainer
      if (chatContainer) {
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight,
          behavior: 'smooth',
        })
      }
    }, 100)
  }

  return { scrollToLast }
}
