<script setup lang="ts">
/**
 * Component remains at 80px height
 * Content can expand outside 80px height
 */
import { computed } from 'vue'

import HeaderButton from './HeaderButton.vue'

import { HeaderStyle, TextMode } from '../Widget.types'

const props = defineProps<{
  aiName: string
  modalTitle: string
  chattingWith: string
  isChatOpen: boolean
  isLoading: boolean
  isStreaming: boolean
  headerStyle: HeaderStyle
  borderRadius: number
  textMode: string
  minimise: boolean
  hasHistory: boolean
  headerAssetUrl: string | undefined
  headerTitleIndent: boolean
  headerAssetWidth: number
  headerAssetTopOffset: number
  headerAssetLeftOffset: number
  supportsLongHeader: boolean
  /** Once chat begins, show short header variation */
  shrinkHeader: boolean
  toggleChat: (bool: boolean | null) => void
}>()

const content = computed(() => {
  return {
    modalTitle: props.modalTitle.replace('{{aiName}}', props.aiName),
    chattingWith: props.chattingWith.replace('{{aiName}}', props.aiName),
  }
})

const showLongHeader = computed(() => {
  return props.supportsLongHeader && !props.shrinkHeader
})

const isCircular = computed(() => {
  return props.headerStyle === HeaderStyle.CIRCULAR
})

const logoHasPos = computed(() => {
  const top = props.headerAssetTopOffset !== 0
  const left = props.headerAssetLeftOffset !== 0
  return { top, left }
})

const logoPosition = computed(() => {
  const hato = props.headerAssetTopOffset
  const halo = props.headerAssetLeftOffset
  return {
    ...(logoHasPos.value.top && { top: `${hato}px` }),
    ...(logoHasPos.value.left && { left: `${halo}px` }),
  }
})

const emit = defineEmits(['resetHistory'])
</script>

<template>
  <div
    class="rw-absolute rw-top-0 rw-z-10 rw-h-[80px] rw-w-full rw-overflow-visible"
    :style="{
      borderRadius: `${borderRadius}px`,
    }"
  >
    <div class="rw-absolute rw-right-[16px] rw-top-[10px] rw-z-10 rw-flex rw-h-[58px] rw-items-center rw-gap-4">
      <HeaderButton
        v-if="hasHistory"
        class="rw-transition-opacity rw-duration-500 disabled:rw-opacity-30"
        variant="bordered"
        @click="emit('resetHistory')"
        :disabled="isLoading || isStreaming"
      >
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.03906 6.82812C3.78125 7.34375 3.17969 7.55859 2.66406 7.34375C2.14844 7.12891 1.89062 6.52734 2.10547 6.01172C3.56641 2.57422 7.00391 0.125 11 0.125C13.2344 0.125 15.3828 1.02734 16.9727 2.61719L19.25 4.89453V2.53125C19.25 1.97266 19.6797 1.5 20.2812 1.5C20.8398 1.5 21.3125 1.97266 21.3125 2.53125V7.34375C21.3125 7.94531 20.8398 8.375 20.2812 8.375H15.4688C14.8672 8.375 14.4375 7.94531 14.4375 7.34375C14.4375 6.78516 14.8672 6.3125 15.4688 6.3125H17.7891L15.5117 4.07812C14.3086 2.875 12.6758 2.1875 11 2.1875C7.86328 2.1875 5.15625 4.12109 4.03906 6.82812ZM19.8516 13.5742C18.3477 16.9688 14.9531 19.375 11 19.375C8.72266 19.375 6.57422 18.5156 4.98438 16.8828L2.75 14.6484V16.9688C2.75 17.5703 2.27734 18 1.71875 18C1.11719 18 0.6875 17.5703 0.6875 16.9688V12.1562C0.6875 11.5977 1.11719 11.125 1.71875 11.125H6.53125C7.08984 11.125 7.5625 11.5977 7.5625 12.1562C7.5625 12.7578 7.08984 13.1875 6.53125 13.1875H4.16797L6.44531 15.4648C7.64844 16.668 9.28125 17.3125 11 17.3125C14.0938 17.3125 16.8008 15.4648 17.918 12.7578C18.1758 12.1992 18.7773 11.9844 19.293 12.1992C19.8086 12.4141 20.0664 13.0156 19.8516 13.5742Z"
            fill="#252B30"
          />
        </svg>
      </HeaderButton>
      <HeaderButton @click="toggleChat(false)">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.1719 2.42188L9.54688 8.04688L15.125 13.625C15.5938 14.0469 15.5938 14.75 15.125 15.1719C14.7031 15.6406 14 15.6406 13.5781 15.1719L7.95312 9.59375L2.375 15.1719C1.95312 15.6406 1.25 15.6406 0.828125 15.1719C0.359375 14.75 0.359375 14.0469 0.828125 13.5781L6.40625 8L0.828125 2.42188C0.359375 2 0.359375 1.29688 0.828125 0.828125C1.25 0.40625 1.95312 0.40625 2.42188 0.828125L8 6.45312L13.5781 0.875C14 0.40625 14.7031 0.40625 15.1719 0.875C15.5938 1.29688 15.5938 2 15.1719 2.42188Z"
            fill="black"
          />
        </svg>
      </HeaderButton>
    </div>

    <!-- Initial header -->
    <h2
      v-if="supportsLongHeader"
      class="rw-absolute rw-left-[40%] rw-top-[40px] rw-z-20 rw-w-[140px] rw-text-2xl rw-font-bold rw-leading-8 rw-opacity-0 rw-transition-opacity rw-duration-100 rw-ease-out sm:rw-left-[180px] lg:rw-left-[180px] lg:rw-top-[45px] lg:rw-w-[180px] lg:rw-text-3xl lg:rw-leading-9"
      :class="[
        showLongHeader && isChatOpen && '!rw-opacity-100 rw-delay-1000 !rw-duration-1000',
        textMode === TextMode.LIGHT ? 'rw-text-white' : 'rw-text-black',
        headerTitleIndent && 'rw-indent-1.5',
      ]"
    >
      {{ content.modalTitle }}
    </h2>

    <!-- Active chat header -->
    <Transition>
      <h2
        v-if="aiName && !showLongHeader"
        class="rw-absolute rw-left-[20px] rw-top-[25px] rw-z-20 rw-text-xl rw-font-bold rw-text-black"
        :class="[textMode === TextMode.LIGHT ? 'rw-text-white' : 'rw-text-black']"
      >
        {{ content.chattingWith }}
      </h2>
    </Transition>

    <div
      class="rw-h-[140px] rw-w-full rw-bg-primaryColor rw-transition-[clip-path] rw-duration-300 rw-ease-out"
      :class="[
        isChatOpen && !hasHistory && 'animate-bouncein',
        {
          'header-gradient': isCircular,
          '[clip-path:ellipse(100%_100%_at_50%_0%)]': isCircular,
          '![clip-path:ellipse(140%_60%_at_50%_0%)]': isCircular && !showLongHeader,
          '[clip-path:xywh(0_0_100%_100%_round_0_0)]': !isCircular,
          '![clip-path:xywh(0_0_100%_60%_round_0_0)]': !isCircular && !showLongHeader,
        },
      ]"
    >
      <!-- Circular highlight -->
      <Transition>
        <div
          v-if="showLongHeader && isCircular"
          class="rw-absolute rw-left-4 rw-top-4 rw-h-[150px] rw-w-[150px] rw-scale-0 rw-bg-headerCircleColor rw-opacity-30 [clip-path:ellipse(50%_50%_at_50%_50%)] lg:rw-h-[180px] lg:rw-w-[180px]"
          :class="[
            isChatOpen ? '!rw-scale-100 rw-transition-transform rw-delay-1000 rw-duration-1000 rw-ease-out' : '',
          ]"
        ></div>
      </Transition>
    </div>

    <!-- Header asset -->
    <div
      v-if="showLongHeader && headerAssetUrl"
      class="rw-absolute rw-z-20 rw-max-w-[30%] rw-text-xl rw-font-bold rw-text-black rw-opacity-0 rw-delay-0 rw-duration-100"
      :class="[
        isChatOpen ? '!rw-opacity-100 rw-transition-opacity !rw-delay-1000 !rw-duration-1000 rw-ease-out' : '',
        !logoHasPos.top && 'rw-bottom-[-85px]',
        !logoHasPos.left && 'rw-left-[15px]',
      ]"
      :style="logoPosition"
    >
      <img :src="headerAssetUrl" :width="headerAssetWidth || 140" :title="content.chattingWith" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header-gradient {
  background: linear-gradient(to bottom right, var(--headerGradientHighlight), var(--primaryColor));
}

.animate-bouncein {
  animation: bounce-in 1.1s 0s both;
}

@keyframes bounce-in {
  0% {
    -webkit-transform: translateY(-140px);
    transform: translateY(-140px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
</style>
