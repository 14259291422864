import { ref, computed } from 'vue'
import { useMessages } from './useMessages'
import { useIsChatOpen } from './useIsChatOpen'

import { generateUUID } from '../utils'

export function useUser({ endpoint, memoryPath, scrollToLast }) {
  const { isChatOpen } = useIsChatOpen()
  const { messages } = useMessages()

  const firstConversation = ref(true)
  const isLoading = ref(false)
  const refreshChatMemory = ref(null)
  const searchFallback = ref(false)

  function setUserUUID() {
    if (!localStorage.getItem('user_uuid_chat_bot')) {
      localStorage.setItem('user_uuid_chat_bot', generateUUID())
    }
  }

  function getUserUUID() {
    return localStorage.getItem('user_uuid_chat_bot') || ''
  }

  function deleteUserUUID() {
    localStorage.removeItem('user_uuid_chat_bot')
  }

  async function refreshMemory() {
    const memory = await getMemory()

    if (memory?.length) {
      firstConversation.value = false
      messages.value = [...memory]

      scrollToLast()
    }
  }

  async function getMemory() {
    const userUuid = getUserUUID()
    const response = await fetch(`${endpoint}${memoryPath}/${userUuid.toString()}?sort=asc`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).catch(() => null)

    if (!response?.json) return null

    const jsonResponse = await response.json()
    const memory = []
    if (jsonResponse.length > 0) {
      jsonResponse.forEach(message => {
        memory.push({
          role: 'user',
          content: message?.input || '',
        })
        memory.push({
          role: 'assistant',
          content: message?.output || '',
        })
      })
    }

    return memory
  }

  return {
    firstConversation,
    isLoading,
    isChatOpen,
    refreshChatMemory,
    deleteUserUUID,
    setUserUUID,
    getUserUUID,
    refreshMemory,
  }
}
