<script setup>
import { computed } from 'vue'
import showdown from 'showdown'

const converter = new showdown.Converter()

const props = defineProps(['content', 'lightMode', 'darkMode'])

const htmlContent = computed(() => {
  if (!props.content) {
    return ''
  }

  const htmlWithConvertedTargetLinks = converter
    .makeHtml(props.content)
    .replace(/<a href/g, '<a target="_blank" rel=”noopener” href')

  return htmlWithConvertedTargetLinks
})
</script>

<template>
  <div
    class="rw-rich-text rw-m-0 rw-min-w-[180px] rw-break-words rw-text-start rw-text-sm rw-leading-5"
    :class="[lightMode && 'rw-rich-text--text-white', darkMode && 'rw-rich-text--text-black']"
    v-dompurify-html="htmlContent"
  ></div>
</template>

<style lang="scss" scoped>
.rw-rich-text {
  :deep() {
    a {
      @apply rw-underline rw-underline-offset-2;
    }

    a,
    b,
    strong {
      @apply rw-font-bold;
    }

    a,
    p,
    ol,
    ul,
    li {
      @apply rw-break-words rw-text-start rw-text-sm rw-leading-5 [&:not(:first-child)]:rw-mt-1;
    }
    p {
      @apply rw-mb-0;
    }

    ol,
    ul {
      @apply rw-ms-4;
    }

    ol {
      @apply rw-list-decimal;
    }

    ul {
      @apply rw-list-disc;
    }
  }

  &--text-white {
    @apply rw-text-white;

    :deep() {
      a,
      p,
      ol,
      ul,
      li {
        @apply rw-text-white;
      }
    }
  }

  &--text-black {
    @apply rw-text-black;

    :deep() {
      a,
      p,
      ol,
      ul,
      li {
        @apply rw-text-black;
      }
    }
  }
}
</style>
