import { ref, watch, computed } from 'vue'

import { useIsChatOpen } from './useIsChatOpen'
import { useMessages } from './useMessages'

export const useShortHeader = (aiName, headerAssetUrl, hasMessageHistory) => {
  const disableLongHeader = !aiName && !headerAssetUrl
  const shortHeaderActive = ref(disableLongHeader || hasMessageHistory)

  const { isChatOpen } = useIsChatOpen()
  const { messages } = useMessages()

  const supportsLongHeader = computed(() => {
    return !!aiName && !!headerAssetUrl
  })

  watch(
    [isChatOpen, messages],
    ([openBool, msgs]) => {
      setTimeout(() => {
        if (openBool && msgs.length) {
          shortHeaderActive.value = true
        } else if (openBool) {
          shortHeaderActive.value = disableLongHeader ? true : false
        }
      }, 1500)
    },
    { immediate: true }
  )

  return { supportsLongHeader, shortHeaderActive }
}
