<script setup lang="ts">
import { computed } from 'vue'

import RateOutputInline from './RateOutput/Inline.vue'
import RateOutputHover from './RateOutput/Hover.vue'

export interface RateOutputProps {
  index: number
  message: any
  rateMessage: string
  isStreaming: boolean
  ratingIndex: any[]
  totalMessages: number
  showRatingOption: boolean
}

const props = defineProps<RateOutputProps>()

const ratingIsPositive = computed(() => {
  return !!props.ratingIndex.find(e => e.index === props.index && e.rating === 1)
})

const component = computed(() => {
  const isNotLastItem = props.totalMessages !== props.index + 1
  return isNotLastItem ? RateOutputHover : RateOutputInline
})
</script>

<template>
  <Component
    :is="component"
    v-bind="props"
    :rate-message="rateMessage"
    :rating-is-positive="ratingIsPositive"
    :show-rating-option="showRatingOption"
  />
</template>

<style lang="scss" scoped></style>
