<template>
  <svg width="141" height="129" viewBox="0 0 141 129" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43.469 0.928002C19.623 2.913 12.459 5.31 6.322 13.357C1.265 19.986 0.898997 22.442 0.851997 50.054L0.807999 75.645L3.89 81.813C8.998 92.034 16.913 95.877 36.308 97.555C40.708 97.936 45.292 98.611 46.495 99.057C47.765 99.527 52.717 105.974 58.293 114.415C67.019 127.626 68.171 128.964 70.82 128.964C72.832 128.964 74.102 128.265 74.91 126.714C75.554 125.476 79.915 118.744 84.601 111.752L93.12 99.04L97.714 98.478C123.525 95.324 129.026 93.545 134.156 86.692C140.37 78.389 140.308 78.76 140.308 49.964C140.308 27.168 140.071 22.94 138.609 19.714C133.678 8.831 126.905 4.567 111.089 2.388C98.394 0.639001 57.537 -0.242998 43.469 0.928002ZM105.004 10.431C121.219 12.004 124.256 13.064 128.506 18.633L131.308 22.303L131.616 46.884C131.785 60.403 131.647 72.953 131.309 74.774C130.492 79.175 124.484 85.683 120.126 86.887C118.209 87.416 111.391 88.366 104.974 88.998C98.558 89.631 91.948 90.725 90.285 91.431C88.107 92.356 85.052 96.102 79.348 104.839C74.995 111.508 71.077 116.964 70.64 116.964C70.204 116.964 66.235 111.572 61.82 104.982C57.405 98.392 52.784 92.385 51.55 91.633C50.317 90.881 43.458 89.719 36.308 89.05C20.647 87.585 16.089 85.856 12.131 79.879L9.308 75.616L8.999 52.04C8.83 39.073 8.968 26.975 9.306 25.154C9.929 21.803 15.536 14.715 19.054 12.832C25.399 9.43699 79.249 7.932 105.004 10.431ZM37.463 36.793C35.246 39.243 35.378 40.549 38.031 42.407C39.929 43.737 44.563 43.949 69.781 43.864C86.021 43.809 100.433 43.454 101.808 43.073C103.425 42.626 104.43 41.534 104.653 39.979C105.341 35.186 103.821 34.964 70.272 34.964C41.36 34.964 38.999 35.096 37.463 36.793ZM38.031 56.521C35.414 58.354 35.207 60.22 37.379 62.393C39.705 64.719 70.911 64.719 73.237 62.393C75.409 60.22 75.202 58.354 72.585 56.521C70.813 55.279 67.315 54.964 55.308 54.964C43.301 54.964 39.803 55.279 38.031 56.521Z"
      fill="currentColor"
    />
  </svg>
</template>
