import { createApp } from 'vue'
import VueDOMPurifyHTML from 'vue-dompurify-html'

import ChatWidget from './Widget.vue'
import { WidgetPropsBackwardCompatible } from './Widget.types'

import { useIsChatOpen } from './composables/useIsChatOpen.js'

declare global {
  interface Window {
    reviumGptWidget: any
  }
}

interface WidgetControls {
  open: () => void
  close: () => void
}

interface InitWidget extends WidgetPropsBackwardCompatible {
  elementId?: string
}

const initialize = ({ elementId, ...appProps }: InitWidget): WidgetControls => {
  const elementId_ = elementId ?? 'revium-gpt-widget'
  let rootElement: HTMLDivElement = document.createElement('div')
  const element = document.getElementById(elementId_)

  if (!element) {
    rootElement.id = elementId_
    document.body.appendChild(rootElement)
  } else {
    rootElement = element as HTMLDivElement
  }

  const { isChatOpen } = useIsChatOpen()
  const app = createApp(ChatWidget, appProps)

  app.use(VueDOMPurifyHTML, {
    default: {
      ADD_ATTR: ['target'],
    },
  })

  app.mount(rootElement)

  return {
    open: () => (isChatOpen.value = true),
    close: () => (isChatOpen.value = false),
  }
}

type ReviumGptWidgetType = {
  init: (options: InitWidget) => void
}

const ReviumGptWidget: ReviumGptWidgetType = {
  init: initialize,
}

/**
 * Widget controls via console:
 *  window.reviumGptWidget.open()
 *  window.reviumGptWidget.close()
 */
if (import.meta.env.DEV) {
  import('./config/revium').then(({ config }: any) => {
    window.reviumGptWidget = initialize(config)
  })
}

/* Functional Widget: ReviumGptWidget.init(target, { ... }) */
export default ReviumGptWidget
