import { ref } from 'vue'

export interface IMessage {
  role: string
  content: string | undefined
}

const messages = ref<IMessage[]>([])
const scrollToLastMessage = ref<() => void>()

export function useMessages(scrollToLast?: () => void) {
  if (scrollToLast) {
    scrollToLastMessage.value = scrollToLast
  }

  const publishMessage = (content: string, role = 'user') => {
    const updatedMessages = [
      ...messages.value,
      {
        role,
        content,
      },
    ]

    if (role === 'user') {
      updatedMessages.push({ content: '', role: 'assistant' })
    }

    messages.value = updatedMessages
    scrollToLastMessage.value?.()
  }

  return { messages, publishMessage }
}
