import { WidgetPropsBackwardCompatible } from '../Widget.types'

export function getApiConfig(widgetProps: WidgetPropsBackwardCompatible) {
  const apikey = widgetProps?.api?.apikey || widgetProps.apikey
  const source = widgetProps?.api?.source || widgetProps.source
  const endpoint = widgetProps?.api?.endpoint || import.meta.env.VITE_API_URL

  if (!apikey || !source || !endpoint) {
    throw new Error('API configuration missing')
  }

  return { apikey, source, endpoint }
}

export function setCssVariables(widgetProps: WidgetPropsBackwardCompatible) {
  const root = document.documentElement
  const primaryColor = widgetProps.colors?.primaryColor || widgetProps.primaryColor
  const secondaryColor = widgetProps.colors?.secondaryColor || widgetProps.secondaryColor
  const { headerCircleColor, headerGradientHighlight } = widgetProps.colors ?? {}

  if (primaryColor) {
    root.style.setProperty('--primaryColor', primaryColor)
  }
  if (secondaryColor) {
    root.style.setProperty('--secondaryColor', secondaryColor)
  }
  if (headerCircleColor) {
    root.style.setProperty('--headerCircleColor', headerCircleColor)
  }
  if (headerGradientHighlight) {
    root.style.setProperty('--headerGradientHighlight', headerGradientHighlight)
  }
}

export function generateUUID() {
  let d = new Date().getTime()
  let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16
    if (d > 0) {
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

interface ReadChunkParams {
  processChunk: (chunk: any) => void
  onIteration?: () => void
}

export function readStream(stream: ReadableStream) {
  const reader = stream.getReader()

  return {
    async onReadChunk({ processChunk, onIteration }: ReadChunkParams) {
      while (true) {
        const { done, value } = await reader.read()
        onIteration?.()
        if (done) break

        const chunk: any = new TextDecoder().decode(value)
        await processChunk(chunk)
      }
    },
  }
}
